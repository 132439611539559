import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import Layout from "../components/Layout";
import Grid from "../components/Grid";
import Typography from "../components/Typography";
import animationData from "../lotties/404-glitch.json";

const NotFound = ({ location }) => (
  <Layout location={location}>
    <Helmet
      title="404 page not found | Linear Finance"
      meta={[
        {
          name: "description",
          content:
            "Linear Finance is a cross-chain compatible, decentralized delta-one asset protocol to cost-effectively and instantly create, manage, and trade liquid assets with unlimited liquidity.",
        },
      ]}
    />
    <Grid container className="pt-120 pb-144">
      <Grid row>
        <Grid column xs={12} md={6} lg={7}>
          <Typography variant="h1" className="mb-12">
            Page not found
          </Typography>
          <Typography variant="subtitle" className="mb-40">
            This page has moved or has never existed. Please use the menu to continue.
          </Typography>
          <Typography variant="h5">Need help?</Typography>
          <Typography variant="paragraph-1" color="gray-700" className="mb-40">
            Fill in{" "}
            <Link to="/contact/" className="hover-underline">
              our contact form
            </Link>{" "}
            and we will contact you as soon as possible.
          </Typography>
          <Typography variant="paragraph-1" weight="bold" component={Link} to="/">
            Linear Finance Homepage
          </Typography>
        </Grid>
        <Grid column xs={12} md={6} lg={5}>
          <Lottie
            width="100%"
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData,
              renderSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            ariaRole="display"
          />
        </Grid>
      </Grid>
    </Grid>
  </Layout>
);

NotFound.propTypes = {
  location: PropTypes.object,
};

NotFound.defaultProps = {
  location: undefined,
};

export default NotFound;
